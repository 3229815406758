








































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import TeaserButton from '@/components/common/TeaserButton.vue'

@Component({
  components: { TeaserButton }
})
export default class KodeEvaluationDashboard extends Vue {

}
